import Overlay from '../classes/overlay';
import features from '/features.json';

$(window).on('ready load', () => {
  // Navigation
  // - Init overlay
  new Overlay({
    toggle: '[data-action="toggle-nav"]',
    selector: `.nav__wrapper, .nav__backdrop[data-action="toggle-nav"], body${
      !features.sidenav ? ', .nav-toggle[data-action="toggle-nav"]' : ''
    }`,
    // toggleClass: '', // Defaults to is-open
  });

  // - Mobile navigation dropdown
  $('.nav__chevron').on('click', function () {
    $(this).toggleClass('is-open');
    $(this).next('.nav__dropdown').slideToggle(200);
  });

  // - Megamenu backdrop
  if (features.megamenu) {
    $('.nav__wrapper .nav__item--top.nav__item--has-children').on(
      'mouseover mouseenter',
      () => {
        if ($(window).width() >= 1200) {
          if (
            !$('.nav__backdrop[data-action="toggle-nav"]').hasClass('is-open')
          ) {
            $('.nav__backdrop[data-action="toggle-nav"]').addClass('is-open');
          }
        }
      },
    );
    $('.nav__wrapper .nav__item--has-children').on('mouseleave', () => {
      if ($(window).width() >= 1200) {
        $('.nav__backdrop[data-action="toggle-nav"]').removeClass('is-open');
      }
    });
  }

  // Filter overlay
  new Overlay({
    toggle: '[data-action="toggle-filter"]',
    selector: '.s7filter, .nav__backdrop[data-action="toggle-filter"], body', // Remove .nav-toggle if side-nav
    // toggleClass: '', // Defaults to is-open
  });
});
