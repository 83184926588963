/* global google */

function new_map($el) {
  const $markers = $el.find('.marker');
  const args = {
    zoom: $el.data('zoom') ? $el.data('zoom') : 16,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    draggable: true,
    mapTypeControl: false,
    navigationControl: false,
    scaleControl: false,
    scrollwheel: false,
    zoomControl: true,
  };
  const map = new google.maps.Map($el[0], args);

  map.markers = [];
  $markers.each(function () {
    add_marker($(this), map);
  });

  center_map(map);
  return map;
}

function add_marker($marker, map) {
  const latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng'),
  );
  const marker = new google.maps.Marker({
    position: latlng,
    map,
  });

  map.markers.push(marker);
  if ($marker.html()) {
    const infowindow = new google.maps.InfoWindow({
      content: $marker.html(),
    });
    google.maps.event.addListener(marker, 'click', () => {
      infowindow.open(map, marker);
    });
  }
}

function center_map(map) {
  const bounds = new google.maps.LatLngBounds();
  $.each(map.markers, (i, marker) => {
    const latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng(),
    );
    bounds.extend(latlng);
  });

  if (map.markers.length == 1) {
    map.setCenter(bounds.getCenter());
  } else {
    map.fitBounds(bounds);
  }
}

$(function () {
  $('.acf-map').each(function () {
    new_map($(this));
  });
});
