import './autoload/bootstrap';

import './global/common';
import './global/nav';
import './global/flickity';
import './global/gmaps';

import features from '/features.json';

if (features.woocommerce) {
  import('./features/woocommerce/checkout');
  import('./features/woocommerce/product');
}

if (features.woocommerce_addtocartmodal) {
  import('./features/woocommerce/addtocart');
}

if (features.search) {
  import('./features/search');
}

import.meta.glob(['../images/**', '../fonts/**']);
