export default class Overlay {
  constructor(args) {
    // prettier-ignore
    if (typeof args !== 'object'
        || typeof args.toggle !== 'string'
        || typeof args.selector !== 'string') return false;
    this.toggle = args.toggle;
    this.selector = args.selector;
    // prettier-ignore
    this.toggleClass = typeof args.toggleClass !== 'undefined' ? args.toggleClass : 'is-open';
    this.width = $(window).width();
    this.isOverlayOpen = 0;
    this.events();
  }

  events() {
    $(window).on('resize', this.handleResize.bind(this));
    $(document).on('keyup', this.handleEscKey.bind(this));
    $(this.toggle).on('click', this.toggleOverlay.bind(this));
  }

  handleResize() {
    if ($(window).width() != this.width) {
      this.closeOverlay();
      this.width = $(window).width();
    }
  }

  handleEscKey(e) {
    if (this.isOverlayOpen && e.key == 'Escape') {
      return this.closeOverlay();
    }
  }

  toggleOverlay(e) {
    e.preventDefault();

    if (this.isOverlayOpen) {
      return this.closeOverlay();
    }

    return this.openOverlay();
  }

  openOverlay() {
    $(this.selector).addClass(this.toggleClass);
    this.isOverlayOpen = !this.isOverlayOpen;
  }

  closeOverlay() {
    $(this.selector).removeClass(this.toggleClass);
    this.isOverlayOpen = !this.isOverlayOpen;
  }
}
