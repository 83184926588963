$(function () {
  $('#modal').on('show.bs.modal', function (e) {
    const button = $(e.relatedTarget);
    const title = button.data('title') ? button.data('title') : '';
    const content = JSON.parse(button.data('content'));
    const video = button.data('video') ? button.data('video') : '';
    const offcanvas = button.data('offcanvas') ? button.data('offcanvas') : '';
    const modal = $(this);

    if (video) {
      modal.addClass('modal-video');
      $('body').addClass('modal-open-video');
    } else if (offcanvas) {
      modal.addClass('modal-offcanvas');
    }

    modal
      .find('.modal-header')
      .prepend(`<span class="modal-title" id="modalLabel">${title}</span>`);
    modal.find('.modal-body').html(content);
  });

  // Remove modal content after its hidden
  $('#modal').on('hidden.bs.modal', function () {
    const modal = $(this);
    $('body').removeClass('modal-open-video');
    modal.removeClass('modal-video');
    modal.removeClass('modal-offcanvas');
    modal.find('.modal-title').remove();
    modal.find('.modal-body').html('');
  });

  // Gravity forms on submit
  $(document).on('gform_confirmation_loaded', function (e, formID) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmission',
      formID: formID,
      formName:
        !!window.suiteSevenGravityFormTitle &&
        !!window.suiteSevenGravityFormTitle[formID]
          ? window.suiteSevenGravityFormTitle[formID]
          : `Undefined - ${formID}`,
    });
  });
});
