// Import flickity
import Flickity from 'flickity';

// Set global arrowShape
const arrowShape = 'M82.6,46H1.3v8.7h81.1L63.9,73l6.2,6.1l29.2-29l-29.2-29l-6.2,6.1L82.6,46z'; // prettier-ignore

// USP Slider
document.querySelectorAll('.usp-slider').forEach((e) => {
  if (e.querySelectorAll('.usp-slider__item').length > 1) {
    const flkty = new Flickity(e, {
      cellAlign: 'left',
      watchCSS: true,
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 5000,
    });

    flkty.resize();
  }
});

// News slider
const initNewsFlickity = function (parent) {
  if (!parent) {
    return;
  }
  parent.querySelectorAll('.news-slider').forEach((e) => {
    if (
      (window.innerWidth < 768 &&
        e.querySelectorAll('.news-slider__item').length > 1) ||
      (window.innerWidth > 767 &&
        window.innerWidth < 1025 &&
        e.querySelectorAll('.news-slider__item').length > 2) ||
      (window.innerWidth > 1024 &&
        e.querySelectorAll('.news-slider__item').length > 3)
    ) {
      const flkty = new Flickity(e, {
        arrowShape,
        cellAlign: 'left',
        contain: true,
        groupCells: true,
      });
      flkty.resize();
    } else if (e.classList.contains('flickity-enabled')) {
      Flickity.data(e).destroy();
    }
  });
};

// Image slider
const initImageFlickity = function (parent) {
  if (!parent) {
    return;
  }
  parent.querySelectorAll('.js-img-slider').forEach((e) => {
    if (e.querySelectorAll('.js-img-slider__item').length > 1) {
      const flkty = new Flickity(e, {
        wrapAround: true,
        arrowShape,
      });

      flkty.resize();
    }
  });
};

// Testimonial slider
const initTestimonialFlickity = function (parent) {
  if (!parent) {
    return;
  }
  parent.querySelectorAll('.js-testimonial-slider').forEach((e) => {
    if (e.querySelectorAll('.js-testimonial-slider__item').length > 2) {
      const flkty = new Flickity(e, {
        cellAlign: 'left',
        groupCells: true,
        contain: true,
        arrowShape,
      });

      flkty.resize();
      let resizeTimer;
      window.addEventListener('resize', () => {
        document
          .querySelectorAll('.js-testimonial-slider__item')
          .forEach((item) => item.classList.add('reset-height'));

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          document
            .querySelectorAll('.js-testimonial-slider__item')
            .forEach((item) => item.classList.remove('reset-height'));
        }, 250);
      });
    }
  });
};

// Logo slider
const initLogosFlickity = function (parent) {
  if (!parent) {
    return;
  }
  parent.querySelectorAll('.js-logo-slider').forEach((e) => {
    if (e.querySelectorAll('.js-logo-slider__item').length > 4) {
      const flkty = new Flickity(e, {
        cellAlign: 'left',
        groupCells: true,
        contain: true,
        arrowShape,
      });

      flkty.resize();
    }
  });
};

// Team slider
const initTeamFlickity = function (parent) {
  if (!parent) {
    return;
  }
  parent.querySelectorAll('.team-slider').forEach((e) => {
    if (e.querySelectorAll('.card-team').length > 1) {
      const flkty = new Flickity(e, {
        watchCSS: true,
        wrapAround: true,
        arrowShape,
      });

      flkty.resize();
    }
  });
};

document.addEventListener('DOMContentLoaded', function () {
  initNewsFlickity(document.querySelector('.block-news'));
  initImageFlickity(document.querySelector('.block-slider'));
  initTestimonialFlickity(document.querySelector('.block-testimonial'));
  initLogosFlickity(document.querySelector('.block-logos'));
  initTeamFlickity(document.querySelector('.block-team'));

  if (typeof acf !== 'undefined') {
    window.acf.addAction('render_block_preview/type=news', function ($block) {
      initNewsFlickity($block.get(0));
    });
    window.acf.addAction('render_block_preview/type=slider', function ($block) {
      initImageFlickity($block.get(0));
    });
    window.acf.addAction(
      'render_block_preview/type=testimonial',
      function ($block) {
        initTestimonialFlickity($block.get(0));
      },
    );
    window.acf.addAction('render_block_preview/type=logos', function ($block) {
      initLogosFlickity($block.get(0));
    });
    window.acf.addAction('render_block_preview/type=team', function ($block) {
      initTeamFlickity($block.get(0));
    });
  }
});
